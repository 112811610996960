<template>
<div>
    <vx-card class="p-0">
        <div class="vx-row items-center">           
            <div class="vx-col w-1/4">
                <vs-input label="Serial" v-model="serialModulo" class="w-full" />        
            </div>                
            <div class="vx-col w-1/4 flex">
                <vs-button class="mt-6" icon="search" color="primary" @click="iniciarConsulta" :disabled="!serialModulo || consultaIniciada"> Consultar</vs-button>
                <vs-button class="ml-2 mt-6" icon="search" color="dark" @click="textoConsulta = null" :disabled="!serialModulo"> Limpar</vs-button>
            </div>
        </div>
    </vx-card>  
    <vs-divider></vs-divider>
    <div class="vx-row" v-if="serialModulo">           
        <div class="vx-col w-1/4">
                <vs-chip :color="getCorStatus">
                        <vs-avatar icon="rss_feed" /> Status
                </vs-chip>      
        </div>        
    </div>
    <div style='font-family: Verdana, sans-serif; overflow: auto; height: calc(100vh - 300px); position: relative;' v-html="textoConsulta">
    </div>
    
</div> 
</template>

<script>
import axios from "@/axios.js"

export default {

    data() {
        return {
            serialModulo: null,
            statusComunicacao: {codigo: false, mensagem: null},
            textoConsulta: null,
            handlerRequisicao: null,
            consultaIniciada: false,
            numeroRequisicao: 0,
            maxRequisicoes: 5
        }
    },
    computed: {
        getCorStatus() {
            if (!this.statusComunicacao.codigo) {
                return "danger"
            }

            return "success";
        }
    },
    methods: {
        getTimestampConsulta() {
            const d = new Date();
            return d.toLocaleString('pt-BR');
        },
        iniciarConsulta(){
            this.numeroRequisicao = 0;
            this.consultaIniciada = true;
            this.handlerRequisicao = setInterval(this.fetchStatus, 1000);
        },
        fetchStatus() {     
            this.numeroRequisicao++;
            this.$vs.loading() 
            const params = new URLSearchParams();
            params.append('outputFormat', 'JSON');
            params.append('serialModulo', this.serialModulo);

            axios.post("/VerificarStatusComunicacaoModulo", params, {              
                    credentials: 'include',
                    responseEncoding: 'iso88591',
                    withCredentials: true } )
            .then((response) => {
                this.$vs.loading.close();
                if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                    this.$router.push({ path: '/' });
                } else {
                   this.statusComunicacao = response.data;
                   if (this.textoConsulta) {
                       this.textoConsulta = this.getTimestampConsulta() + " > " +this.statusComunicacao.mensagem + '<br/>' + this.textoConsulta ;
                   } else {
                       this.textoConsulta = this.getTimestampConsulta() + " > " + this.statusComunicacao.mensagem;
                   }
                }
            })
            .catch((error) => { 
                this.$vs.loading.close();
                /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - fetchLista - " + error); 
            })    

            if (this.numeroRequisicao === this.maxRequisicoes) {
                clearInterval(this.handlerRequisicao);
                this.consultaIniciada = false;
            }
        },
    }
}
</script>
